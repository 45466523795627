import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private routes: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      let nextPath = state.url;
      console.log("AuthAuthGuard --> Revisar Acceso:", nextPath);
      console.log("state:",state.url + " | " + nextPath);
      console.log("next-->","/" + next.routeConfig.path);

      if(nextPath === "/login"){
        return true;
      }

      if(sessionStorage.getItem('access_token') != null
      && sessionStorage.getItem('refresh_token') != null
      && sessionStorage.getItem('menu') != null){

        let menu = JSON.parse(sessionStorage.getItem('menu'));
        console.log(menu);
        for(let itemMenu of menu.accessURL ){
          if(nextPath === itemMenu){
           console.log("Tiene permiso a ruta menu-------------------------> " +nextPath +  " " + itemMenu);
            return true;
          }else{
            console.log("Otro caso ruta menu-------------------------> " +nextPath +  " " + itemMenu);
          }
        }

        console.log("AuthAuthGuard NO tiene permisos --> Login "+ nextPath);
        this.routes.navigate(['/acceso-denegado']);
        return false;
      } else {
        console.log("AuthAuthGuard --> Login");
        this.routes.navigate(['/login']);
        return false;
      }
  }
}
