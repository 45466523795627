import { emaUtils } from './../component/ema-utils/documents-utils';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/shareReplay';

@Injectable()
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private router: Router) { }

  login(username: string, password: string) {
  // debugger;

    sessionStorage.setItem('access_token' , 'temporal');
    const params = new HttpParams({
      fromObject: {
        user_email: username,
        user_password: password,
        language_id: '1'
      }
    });
    return this.httpClient.post(environment.api.login,
    params,
    {
      headers: new HttpHeaders({
           'Content-Type':  'application/x-www-form-urlencoded',
         })
    }).do((authResult: any) => {
      localStorage.setItem('session_active', 'active');
      localStorage.setItem('user_id_profile', authResult.data.user_data.user_id);
      sessionStorage.setItem('access_token', authResult.data.access_token);
      sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
      localStorage.setItem('customer', authResult.data.user_data.user_id);
      localStorage.setItem('customerBranchId', authResult.data.branch_office.id);
      localStorage.setItem('customerBranch', authResult.data.branch_office.company_id);
      localStorage.setItem('user', authResult.data.user_data.user_detail.name /*
      + ' '
      + authResult.data.user_data.user_detail.middle_name
      + ' '
      + authResult.data.user_data.user_detail.last_name*/);
      localStorage.setItem('user_role', authResult.data.user_data.user_detail.role);
      /////branch_office_name SE TOMA PARA MOSTRAR EN EL PERFIL DEL USUARIO -  para el cliente se está tomando el campo commercial_name de la BD
      localStorage.setItem('branch_office_name', authResult.data.branch_office.name);
      localStorage.setItem('company_name', authResult.data.branch_office.company_name);
      localStorage.setItem('esEmpleado', authResult.data.user_data.is_employee);
      localStorage.setItem('company1_id', authResult.data.branch_office.company1_id);
      localStorage.setItem('company_id', authResult.data.branch_office.company_id);
      localStorage.setItem('company_branch_id', authResult.data.branch_office.company_branch_id);
      localStorage.setItem('user_name_prof', username);
     // console.log(username + ">>>>>>>>> ");
    })
    .shareReplay();
  }

  refreshToken(access_token: string, refresh_token: string) {
   // console.log("refreshToken session.....");
    //session_active == 'null' ? "nulo" : 0
    if (localStorage.getItem("session_active") == "inactive" || localStorage.getItem("session_active") == 'null'){
      this.logout();
     // console.log("Session cerrada en otra pestaña");
      return;
    }else if (localStorage.getItem("session_active") == "active"){
      //console.log("Session abierta...");
    }
    const params = new HttpParams({
      fromObject: {
        token: access_token,
        refresh_token: refresh_token,
        company_id: localStorage.getItem('company1_id'),
        company_branch_id: localStorage.getItem('company_branch_id'),
        customer_id: localStorage.getItem('customerBranchId'),
        customer_branch_id: localStorage.getItem('customerBranch')
      }
    });
    return this.httpClient.post(environment.api.refreshToken,
    params,
    {
      headers: new HttpHeaders({
           'Content-Type':  'application/x-www-form-urlencoded',
         })
    }).do((authResult: any) => {
      sessionStorage.setItem('access_token', authResult.data.access_token);
      sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
    })
    .shareReplay();
  }
  obtenerMenu() {
    let rol = localStorage.getItem('user_role');
    return this.httpClient.get(environment.api.menu + '/'
    + rol).do((menuResp: any) => {
      //console.log('Todo el menu:', menuResp);
      let accessURL = [];
      let defaultURL = '';
      //filtrar sidebar
      let menuTemp = menuResp.data.filter(item => {

      item.submenu = item.submenu.filter(subitem => {

        accessURL.push(subitem.path);
        //console.log("MENU--------------------");
     //   console.log(subitem);
         if(subitem.is_default_page == 1) {
          //console.log("Default page--------------------");
          localStorage.setItem('menu', item.menu_id);
          localStorage.setItem('submenu', subitem.menu_id);
           defaultURL = subitem.path;
         }
         return (subitem.is_in_menu == 1);
        });
       return (item.is_in_menu == 1);
      });
      //mapear sidebar
      menuTemp.map(item =>{
        item.title = item.module;
        item.class = item.class_;
        //console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>");
        //console.log(item.submenu);
        item.submenu.map(subitem => {
          //si es document se agregan parametros en url
          if( (subitem.path === '/document') || (subitem.path === '/customer-document' ) ) {

            subitem.path = subitem.path + '/' + item.menu_id + '/' + subitem.menu_id;
          //  console.log("Paginas que tiene acceso: " + subitem.path);
            accessURL.push(subitem.path);

          }else if ((subitem.path === '/notification')){
            //console.log("Paginas que tiene acceso: " + subitem.path);
            subitem.path = subitem.path;
            accessURL.push(subitem.path);
          }

          subitem.class = subitem.class_;
          subitem.submenu = [];
          return subitem;
        });
        return item;
      });
      let menu = {
        data: menuTemp,
        accessURL : accessURL,
        defaultURL: defaultURL
      };
      //console.log('Filtro menu sidebar:', menuTemp);
      //console.log('---> Url access:', accessURL);
      //console.log(JSON.stringify(menu));
      sessionStorage.setItem('menu', JSON.stringify(menu));
    });
  }

  logout() {
    //console.log("user id:: " + localStorage.getItem('customer'));
     // remove user from local storage to log user out
     const params = new HttpParams({
      fromObject: {
        user_id: localStorage.getItem('customer')
      }
    });
    return this.httpClient.post(environment.api.logout,
              params,
              {
               headers: new HttpHeaders({
                'Content-Type':  'application/x-www-form-urlencoded',
              })}).do((data) => {
                  sessionStorage.removeItem('access_token');
                  sessionStorage.removeItem('refresh_token');
                  sessionStorage.removeItem('menu');
                  localStorage.removeItem('customer');
                  localStorage.removeItem('customerBranch');
                  localStorage.removeItem('user');
                  localStorage.removeItem('branch_office_name');
                  localStorage.removeItem('menu');
                  localStorage.removeItem('submenu');
                  localStorage.removeItem('esEmpleado');
                  localStorage.removeItem('company1_id');
                  localStorage.removeItem('company_branch_id');
                  localStorage.removeItem('customerBranchId');
                  //Se elimina menu
                  localStorage.removeItem('titleMenu');
                  localStorage.clear();
                  sessionStorage.clear();
                  this.router.navigate(['/login']);
              } );
  }
  cleanCache(){
    //console.log("User is logged - " + localStorage.getItem("'user"));
    localStorage.setItem("session_active", "inactive");

    localStorage.removeItem('username') ;
    localStorage.removeItem('passwor') ;

    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('menu');
    localStorage.removeItem('customer');
    localStorage.removeItem('customerBranch');
    localStorage.removeItem('user');
    localStorage.removeItem('branch_office_name');
    localStorage.removeItem('menu');
    localStorage.removeItem('submenu');
    localStorage.removeItem('esEmpleado');
    localStorage.removeItem('company1_id');
    localStorage.removeItem('company_branch_id');
    localStorage.removeItem('customerBranchId');
    //Se elimina menu
    localStorage.removeItem('titleMenu');
    localStorage.clear();
    sessionStorage.clear();
  }
}
