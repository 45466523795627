import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './login/login.component';
import { LoginV10Component } from './login-v10/login-v10.component';
import { AccesoDenegadoComponent } from './acceso-denegado/acceso-denegado.component';
import { AuthGuard } from './auth.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'customer-document',
        loadChildren: './component/document-viewer/document-viewer/document-viewer.module#DocumentViewerModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'customer-document/:fatherId/:headerId',
        loadChildren: './component/document-viewer/document-viewer/document-viewer.module#DocumentViewerModule',
        //loadChildren: './component/pdf-viewer-customers/pdf-viewer-customers.module#PdfViewerCustomersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'upload',
        loadChildren: './component/uploadfile/upload.module#UploadModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'user-list',
        loadChildren: './component/listuser/listuser.module#ListUserModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'user',
        loadChildren: './component/user/user.module#UserModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'document',
        loadChildren: './component/document/document.module#DocumentModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'document/:fatherId/:headerId',
        loadChildren: './component/document/document.module#DocumentModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'publishDocument',
        loadChildren: './component/document/publishdocument/publishdocument.module#PublishDocumentModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'calendario',
        loadChildren: './component/calendario/calendario.module#CalendarioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reportes',
        loadChildren: './component/dashboards/dashboards.module#DashboardsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reportes-detalles',
        loadChildren: './component/dashboards/dashboarddetails/dashboarddetails/dashboarddetails.module#DashboarddetailsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'notificaciones',
        loadChildren: './component/notifications/notifications/notifications.module#NotificationsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sucursales-clientes',
        loadChildren: './component/customerbranches/customerbranches/customerbranches.module#CustomerbranchesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'configuracion-sucursales',
        loadChildren: './component/customerbranches/customerbranchescrud/customerbranchescrud/customerbranchescrud.module#CustomerbranchescrudModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'clientes',
        loadChildren: './component/customers/customers/customers.module#CustomersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'configuracion-clientes',
        loadChildren: './component/customers/customerscrud/customerscrud/customerscrud.module#CustomerscrudModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'perfil-usuario',
        loadChildren: './component/userprofile/userprofile/userprofile.module#UserprofileModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'document-viewer',
        loadChildren: './component/document-viewer/document-viewer/document-viewer.module#DocumentViewerModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'user-admin',
        loadChildren: './component/user-administration/user-administration/user-administration.module#UserAdministrationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'notification',
        loadChildren: './component/notification-screen/notification-screen/notification-screen.module#NotificationScreenModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: './component/ema-settings/ema-settings.module#EmaSettingsModule',
        canActivate: [AuthGuard]
      }
    ]
  },
  /*{
    path: '**',
    redirectTo: '/login'
  },*/
  {
    path: 'login',
    component: LoginV10Component,
  },
  {
    path: 'acceso-denegado',
    component: AccesoDenegadoComponent
  }
];
