import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFViewerComponent } from 'src/app/pdfviewer/pdfviewer.component';
import { EmaPaginationComponent } from 'src/app/component/ema-pagination/ema-pagination.component';
import { FormsModule } from '@angular/forms';
import { DragDropModule,DragDropRegistry  } from '@angular/cdk/drag-drop';
import { ScrollingModule,ViewportRuler, ScrollDispatcher } from '@angular/cdk/scrolling';
import { PlatformModule,Platform} from '@angular/cdk/platform';
import { SpinnerComponent} from 'src/app/shared/spinner.component';
@NgModule({
  declarations: [PDFViewerComponent,EmaPaginationComponent,SpinnerComponent],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ScrollingModule,
    PlatformModule
  ],
  exports:[PDFViewerComponent,EmaPaginationComponent,SpinnerComponent],
  providers: [ViewportRuler,Platform,DragDropRegistry,ScrollDispatcher]
})
export class SharedModuleModule { }
