import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators/switchMap';
import { finalize } from 'rxjs/operators/finalize';
import { timeout } from 'rxjs/operators/timeout';
import { catchError } from 'rxjs/operators/catchError';
import { _throw } from 'rxjs/observable/throw';
import { retry } from 'rxjs/operators/retry';
import { of } from 'rxjs/observable/of';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map'

@Injectable({
  providedIn: 'root'
})
export class AcceptfolderService {

  constructor(private httpClient : HttpClient){}

  crearAceptarCarpeta(accepfolder_data){
    return this.httpClient.post(environment.api.aceptarCarpeta, accepfolder_data,{
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
      })
    }).pipe(timeout(600000),
    catchError((e, c) => {
      return _throw(e)
    }),
    switchMap(f => {
      console.log('do something with ------' + JSON.stringify(f));
      return of(f) }),
    finalize(() => {
      console.log('finilize')
    })).map(data=>
      data);
  }

  actualizarAceptarCarpeta(accepfolder_data){
    return this.httpClient.put(environment.api.aceptarCarpeta, accepfolder_data,{
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
      })
    }).pipe(timeout(600000),
    catchError((e, c) => {
      return _throw(e)
    }),
    switchMap(f => {
      console.log('do something with ------' + JSON.stringify(f));
      return of(f) }),
    finalize(() => {
      console.log('finilize')
    })).map(data=>
      data);
  }
  NotificationGeneral(notification_data){
    console.log("Ejecutar notification");
    return this.httpClient.put(environment.api.notificationgeneral,notification_data,{
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
      })
    }).pipe(timeout(600000),
    catchError((e, c) => {
      return _throw(e)
    }),
    switchMap(f => {
      console.log('do something with ------' + JSON.stringify(f));
      return of(f) }),
    finalize(() => {
      console.log('finilize')
    })).map(data=>
      data);
  }
/*
  obtenerAceptarCarpeta(customer_id, customer_branch_id, option) {
    return this.httpClient.get(environment.api.aceptarCarpeta + '/' + customer_id + '/' + customer_branch_id + '/' + option, {
      headers: {'token': sessionStorage.getItem('access_token') , 'language_id': '1'}
    }).map(data => data);
  }*/

  obtenerAceptarCarpeta(customer_id, customer_branch_id, option) {
    return this.httpClient.get(environment.api.aceptarCarpeta + '/' + customer_id + '/' + customer_branch_id + '/' + option, {
      headers: {'token':sessionStorage.getItem('access_token') , 'language_id': '1'}
    }).map(data =>
    data);
  }

}
