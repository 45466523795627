import { Component, OnInit, Input, HostBinding, Output, EventEmitter, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { NumberSelectionBehavior } from 'aws-sdk/clients/chime';
import { folderAcceptedReport } from 'src/app/model/folderAccepted';
import { PagesIds, strParameters } from 'src/app/model/general';

@Component({
  selector: 'ema-pagination',
  templateUrl: './ema-pagination.component.html',
  styleUrls: ['./ema-pagination.component.css','./ema-pagination.component.scss']
})
export class EmaPaginationComponent implements AfterViewInit  {

  @Input() perPages: number;
  @Input() totalitems:number;
  @Input() pages: folderAcceptedReport[]=[];

  @Output() currentPageEvent = new EventEmitter<number>();
  @Output() perPageEvent = new EventEmitter<strParameters>();

  pagesAr: PagesIds[]=[];
  endPage: number;
  strParams={} as strParameters;
  currentPage:number;
  finalPage:number;
  totalPages:number;
  constructor() { }

  getPages(totalIt:number, selected_id: number){
    /*
    //console.log("Per page " + this.perPages);
    //console.log("Total items " +  totalIt);
    */
    ////console.log(this.pages);
    this.pagesAr = [];
    this.endPage = Math.ceil( totalIt / this.perPages);
    let pages_t: number;
    pages_t= Math.ceil( totalIt / this.perPages);

    this.totalPages=pages_t;
    ////console.log(" (totalIt%this.perPages) "+ (totalIt%this.perPages) + " per page "+this.perPages + " "+pages_t);
/*    if ((totalIt % this.perPages) > 0){
      pages_t += 1;
    }*/

    ////console.log( (totalIt%this.perPages)+ "--> " + pages_t);
    this.finalPage = pages_t;

    for (let i = 0; i < pages_t; i++){
      const t = {} as PagesIds;
      t.id = i + 1;
      if (t.id==selected_id){
        t.style = "selected";
      }else{
        t.style = "pagination-items";
      }
      this.pagesAr.push(t);
    }
    this.paginatedPages(0);
  }
  counter_= 0;
  max_ = 10;
  pagesArPaginates: PagesIds[]=[];
  getFinalPaginatedSegment(){
    let segment = this.pagesAr.length % this.max_;
    //console.log("ultimo segmento " + (this.pagesAr.length-segment));
    this.pagesArPaginates = [];
    this.counter_ = 0;
    let start_=0;
    if (segment==0){
      start_=this.pagesAr.length - this.max_;
    } else{
      start_ = this.pagesAr.length-segment;
    }
    this.current_start = start_;
    for (let i = start_; i < this.pagesAr.length; i++){
        //console.log(this.counter_);
        this.pagesArPaginates[this.counter_] = this.pagesAr[i];
        this.counter_++;
    }

  }
  staticAlertClosedBuscar = true;
  alertBuscar: IAlert;
  paginatedPages(startPage:number){
    this.counter_ = 0;

    this.pagesArPaginates=[];
    console.log("startPage " +startPage);
    if (startPage < 0){startPage = 0}
    for (let i = startPage; i < this.pagesAr.length; i++){
      if (this.counter_ <= this.max_ ){
        //console.log(this.counter_);
        this.pagesArPaginates[this.counter_] = this.pagesAr[i];
        if (this.counter_ == this.max_){
          break;
        }
        this.counter_++;
      }
    }

  }

  changePerPage(){
    ////console.log(">>>>>>>>>> " + this.perPages);
    if (this.perPages > 0 && this.perPages <= this.totalitems){
      this.strParams.perPage = this.perPages;

    }else{
      this.perPages = 10;
      this.strParams.perPage = this.perPages;
    }
    this.strParams.currentPage =  this.currentPage;
    this.getPages(this.totalitems, 1);
    this.perPageEvent.emit(this.strParams);

  }
  selectStart(id: number){
    this.currentPage=id;

    let i=0;
    this.pagesAr.map((r)=>{

      if (id == r.id){
        ////console.log(id + "  <----->  " + r.id + " " + this.pagesAr[i].id);
        this.currentPage = id;
        this.pagesAr[i].style="selected";
      }else{
        this.pagesAr[i].style="pagination-items";
      }
      i++;
      //this.pagesAr.push(str);
    });
    this.getPages(this.totalitems, id);
    this.currentPageEvent.emit(id);
    //this.paginatedPages(id);
  }
  selectEnd(){
    this.currentPage = this.endPage;
    let id = this.currentPage;
    let i = 0;
    this.pagesAr.map((r)=>{
      ////console.log(id+ "  <----->  " + r.id + " " + this.pagesAr[i].id);
      if (id == r.id){
        this.currentPage = id;
        this.pagesAr[i].style="selected";
      }else{
        this.pagesAr[i].style="pagination-items";
      }
      i++;
      //this.pagesAr.push(str);
    });

    this.currentPageEvent.emit(this.endPage);
    this.getFinalPaginatedSegment();
  }
  selectPage(id:number, str: PagesIds){
    ////console.log("page selected " + id);
    let i = 0;
    ////console.log("----->  " + str);
    this.pagesAr.map((r)=>{
      ////console.log(id+ "  <----->  " + r.id + " " + this.pagesAr[i].id);
      if (id == r.id){
        this.currentPage = id;
        this.pagesAr[i].style="selected";
      }else{
        this.pagesAr[i].style="pagination-items";
      }
      i++;
      //this.pagesAr.push(str);
    });


    ////console.log(this.pagesAr);
    this.currentPageEvent.emit(id);
  }
  current_start = 0;
  backPage(i: number){

    this.currentPage += i;
    if (this.currentPage <= 0){
      this.currentPage = 1;
    }
    let j = 0;
    ////console.log("----->  " + this.currentPage);
    this.pagesAr.map((r)=>{
      ////console.log(id+ "  <----->  " + r.id + " " + this.pagesAr[i].id);
      if (this.currentPage == r.id){
        this.currentPage = this.currentPage;
        this.pagesAr[j].style="selected";
      }else{
        this.pagesAr[j].style="pagination-items";
      }
      i++;
      //this.pagesAr.push(str);
    });
    this.getPages(this.totalitems, this.currentPage);
    this.currentPageEvent.emit(this.currentPage);
    this.q_ = (this.current_start + 1)%11;
    //console.log("back page= "+this.q_ );
    if (this.q_  <= this.max_ &&  this.currentPage != 0){
      this.current_start -= 1;
      this.paginatedPages(this.current_start);
    }
  }
  //  q = (p + 1) % n;
  q_:number;
  nextPage(i: number){
    this.currentPage += i;
    ////console.log("this.currentPage " + this.currentPage);

    if (this.currentPage >= this.finalPage){
      this.currentPage = this.finalPage;
    }
    let j = 0;
    ////console.log("----->  " + this.currentPage);

    this.pagesAr.map((r)=>{
      ////console.log(id+ "  <----->  " + r.id + " " + this.pagesAr[i].id);
      if (this.currentPage == r.id){
        this.currentPage = this.currentPage;
        this.pagesAr[j].style="selected";
      }else{
        this.pagesAr[j].style = "pagination-items";
      }
      i++;
      //this.pagesAr.push(str);
    });
    this.getPages(this.totalitems, this.currentPage);
    this.currentPageEvent.emit(this.currentPage);

    this.q_ = (this.currentPage+1)%11;


    if (this.q_  >= 0 &&  this.currentPage >= this.max_){
      this.current_start += 1;
      this.paginatedPages(this.current_start);
    }

  }
  ngOnInit() {
    this.currentPage = 1;
    this.alertBuscar = {
      id: 1,
      type: 'danger',
      message: 'Se requiere seleccionar todos los campos'
    };
    ////console.log( "--->pages ");
    ////console.log(this.totalitems + " ---------------------------------> " + this.perPages);
    //this.getPages(10, 1);
  }
  ngAfterViewInit(){
    ////console.log("After view init " + this.totalitems);

    //this.getPages(5);
  }

}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}
