export class PdfPageModel {
    divPage: any;
    divLoading: any;
    divCanvasWrapper: any;
    canvas: any;
    dataLoaded: boolean = false;
    rotation: any;
    scale: any;
    page: any;
    renderingPage: boolean = false;
    constructor(divPage,divLoading,divCanvasWrapper,canvas){
        this.divPage = divPage;
        this.divLoading = divLoading;
        this.divCanvasWrapper = divCanvasWrapper;
        this.canvas = canvas;
        this.dataLoaded = false;
        this.rotation = 0;
        this.scale = 1;
        this.page = null;
        this.renderingPage = false;
    }

}
