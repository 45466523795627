import * as AWS from "aws-sdk";
import * as JSZip from "jszip";
import * as FileSaver from "file-saver";
import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";

export class emaUtils {
  encPassword = "mateos_$";
  padWithZeroes(number, length) {
    var my_string = "" + number;
    while (my_string.length < length) {
      my_string = "0" + my_string;
    }

    return my_string;
  }

  stringMatching(text: string, patern: string) {
    //var kmp = require("kmp");
    // console.log(kmp(('CV ADAN MATEOS HERNANDEZ').toLowerCase(), ('').toLowerCase())); // 13
  }
  validityDateValidation(start_date, end_date, validity_type) {
    let date_mng = new DatePipe("en-US");
    let today_ = new Date();
    //console.log("start_date " + start_date);

    let today_str = date_mng.transform(today_, "yyyy-MM-dd");
    let sater_date_ = date_mng.transform(start_date, "yyyy-MM-dd");
    let end_date_ = date_mng.transform(end_date, "yyyy-MM-dd");

    let day_end_ = new Date(end_date).getTime();
    let days_start = new Date(start_date).getDay() - new Date().getDay();
    let days_end = new Date().getDay() - new Date(end_date).getDay();
    ////////////////////

    var millisecondsPerDay = 1000 * 60 * 60 * 24;
    var millisBetween = day_end_ - new Date(today_str).getTime();
    var days = millisBetween / millisecondsPerDay;
    //////////////////////
    //console.log("end_date " + end_date+ " - today_ " + today_str + " dt1[2] >>>>>>>>> days = "  + days);
    if (validity_type == "start") {
      return days;
    } else if (validity_type == "end") {
      return days;
    }
  }
  getSignedUrlAWS(bucket, pathFile): string {
    const aws = require("aws-sdk");
    let s3 = new AWS.S3({
      accessKeyId: "AKIAZI5VJPNY4HKDNAEK",
      secretAccessKey: "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",
      region: "us-east-2",
    });
    const url = s3.getSignedUrl("getObject", {
      Bucket: bucket,
      Key: pathFile,
      Expires: 8000,
      ResponseContentType: "application/pdf",
    });
    return url;
  }

  async documentsDownloading(folder_name, filesList) {
    const zip = new JSZip();
    const name = folder_name + ".zip";
    let files_: any[];

    for (let i = 0; i < filesList.length; i++) {
      let file_url = this.getSignedUrlAWS(
        filesList[i].bucket_name,
        filesList[i].key
      );
      //console.log("file to download: " + this.documentsGeneral[i].filename_path);
      const element = filesList[i].original_file_name;
      const file_data: any = await fetch(file_url).then((res) => res.blob());

      var binaryData = [];
      binaryData.push(file_data);
      const url: any = new Blob(binaryData, { type: "application/pdf" });
      zip.file(
        folder_name + "/" + filesList[i].file_track_name + "/" + element,
        url
      );
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });

    //   let filename = original_file_name;
    //return dataURL;
    /*
    var binaryData = [];
    binaryData.push(data);
    window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
    */
    /* if(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1){
      window.open(file_url);
    }else{
      let blob = dataURL;
      /*var binaryData = [];
      binaryData.push(dataURL);
      let url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      //document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);*/

    //}
  }
  cleanCache() {
    //console.log("User is logged - " + localStorage.getItem("'user"));
    localStorage.setItem("session_active", "inactive");

    localStorage.removeItem("username");
    localStorage.removeItem("passwor");

    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("menu");
    localStorage.removeItem("customer");
    localStorage.removeItem("customerBranch");
    localStorage.removeItem("user");
    localStorage.removeItem("branch_office_name");
    localStorage.removeItem("menu");
    localStorage.removeItem("submenu");
    localStorage.removeItem("esEmpleado");
    localStorage.removeItem("company1_id");
    localStorage.removeItem("company_branch_id");
    localStorage.removeItem("customerBranchId");
    //Se elimina menu
    localStorage.removeItem("titleMenu");
    localStorage.clear();
    sessionStorage.clear();
  }
  /*  convertText(conversion:string, plainText:string, encryptText: string) {
    if (conversion == "encrypt") {
      return  CryptoJS.AES.encrypt(plainText.trim(), this.encPassword.trim()).toString();
    }
    else {
      return  CryptoJS.AES.decrypt(encryptText.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8);
    }
  }*/
}
