import { Component, OnInit } from '@angular/core';

import { AcceptfolderService } from 'src/app/services/acceptfolder.service';
import { RouterModule, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { emaUtils } from '../component/ema-utils/documents-utils';


@Component({
  selector: 'app-login-v10',
  templateUrl: './login-v10.component.html',
  styleUrls: ['./login-v10.component.css', './login-v10.component.scss'],
  providers: [AuthService, emaUtils]
})
export class LoginV10Component implements OnInit {
  msg = '';
  staticAlertClosedLogin = true;
  alertLogin:IAlert;
  development_indicator:String;
  hidden_denvironment_text:boolean;

  constructor(
    private tools: emaUtils,
    private authService: AuthService,
    private routes: Router,
    private acceptfolder: AcceptfolderService) {
    //console.log("Ambiente:", environment.ambiente);

    if (!environment.production){
      this.development_indicator = environment.descrpcion_ambiente;
      this.hidden_denvironment_text = false;
    }else{
      this.development_indicator = "";
      this.hidden_denvironment_text = false;
    }
    this.alertLogin = {
      id: 1,
      type: 'danger',
      message: 'Se requiere seleccionar todos los campos..'
    };
  }

  loginform = true;
  recoverform = false;
  ennableInputs = false;
  ennableSpinner = true;

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  ngOnInit() {
    console.log("Starting loggin...");
    let session_active = localStorage.getItem("session_active");

    if ( session_active == "inactive" ||  session_active == null){
      console.log("---> session inactive " + localStorage.getItem('session_active'));
      this.routes.navigate(['/login']);
    }else{
      console.log("---> session active " + localStorage.getItem('session_active'));
      this.check(localStorage.getItem("username_enc"), localStorage.getItem("psw_enc"));
    }
  }
  checkLogginActive(){
    let session_active = localStorage.getItem("session_active");
    if ( session_active == "active" ){
      console.log("NUevo ---> session active " + localStorage.getItem('session_active'));
      this.check(localStorage.getItem("username_enc"), localStorage.getItem("psw_enc"));
    }
  }
  check(uname: string, p: string) {

    this.tools.cleanCache();
    localStorage.setItem("username_enc", uname);
    localStorage.setItem("psw_enc", p);
    //Validar
   // debugger;
    this.ennableInputs = true;
    this.ennableSpinner = false;
    this.staticAlertClosedLogin = true;
    if(uname.length > 0 && p.length > 0) {

          this.authService.login(uname, p).subscribe( (res: any) => {
            this.msg = "";
            if (res.code  != '200') {
              this.staticAlertClosedLogin = false;
              this.alertLogin.type = 'danger';
              this.alertLogin.message = 'Error de login.';
              this.resetLogin();
              this.ennableSpinner = true;
              this.ennableInputs = false;
            } else {
              this.authService.obtenerMenu().subscribe((respMenu: any) => {
                //buscar url default
                let i = '';
                for(let itemMenu of respMenu.data){
                  for(let itemSubMenu of itemMenu.submenu){
                    if(itemSubMenu.is_default_page == 1){
                      i = itemSubMenu.path;
                      //console.log("Se encontro default");
                    }
                  }
                }

                if( i.length == 0) {
                  //console.log('no se encontro default, se asigna por perfil');
                  if(res.data.user_data.is_employee == 1) {
                    i = '/reportes';
                  } else if (res.data.user_data.is_employee == 0 ) {
                    ///GET FOLDER ACCEPT DATA
                    //console.log("Respuesta ----- ****");

                    i = 'notification'//'/customer-document';
                  } else {
                    i = '/login';
                  }
                }
                //console.log('URL default:', i);
                //console.log("routing to "  + i);
                this.routes.navigate([i]);
              },(err: any) => {
                //console.log("Error al consultar el menu -->",err);
              }
              );

            }
          },
          (err: any) => {
            this.staticAlertClosedLogin = false;
            this.alertLogin.type = 'danger';
            this.alertLogin.message = 'Usuario o contraseña incorrecto.';
            this.ennableSpinner = true;
            this.ennableInputs = false;
            this.resetLogin();
          }
        );
    } else {
      this.staticAlertClosedLogin = false;
      this.alertLogin.type = 'danger';
      this.alertLogin.message = 'Se requiere usuario y contraseña.';
      this.resetLogin();
      this.ennableSpinner = true;
      this.ennableInputs = false;
    }
  }

  resetLogin(){
    setTimeout(() => {this.staticAlertClosedLogin = true}, 1500);
  }
}


export interface IAlert {
  id: number;
  type: string;
  message: string;
}
