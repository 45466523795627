import { Component, OnInit, ViewChild, AfterViewInit, Renderer2, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import SignaturePad from 'signature_pad';
import { DocumentsService } from 'src/app/services/documents.service';
import { uploadFileModel } from 'src/app/model/uploadFileModel';
import * as AWS from 'aws-sdk';
import { PdfPageModel } from 'src/app/model/pdf-page'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragDrop,DragRef,DragRefConfig, CdkDragStart,CdkDragEnd, CdkDragDrop} from '@angular/cdk/drag-drop';
declare const PDFLib: any;
const { PDFDocument, StandardFonts, rgb, degrees } = PDFLib

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.css'],
  providers:  [
    DocumentsService
  ]
})
export class PDFViewerComponent implements OnInit, AfterViewInit, OnChanges{
  // Variables firmas
  @Input() allowSignature: boolean = true;
  @ViewChild('sPad',{static: true}) signaturePadElement;
  signaturePad: any;
  signature: any;
  arrSignatures: any;
  hideCaptureSignature: boolean = true;

  //Vaiables pdf
  @Input() url: string;
  @Input() fileU: any;
  @Output() savePDFEvent = new EventEmitter<string>();
  @ViewChild('viewer',{static: true}) htmlPdfViewer;
  @ViewChild('viewerContainer',{static: true}) viewContainer;
  @ViewChild('secondaryToolbar',{static: true}) secondaryToolbar;
  @ViewChild('loadingBar',{static: true}) loadingBar;
  @ViewChild('loadingBarProgress',{static: true}) loadingBarProgress;
  @ViewChild('savingSignature',{static: true}) savingSignature;
  pdfjsLib: any;
  hidepdfloading: boolean = true;
  scale: any;
  urlPDF: string;
  pdfDocument: any;
  numPage: number;
  elementNumPage: any;
  totalPage: number;
  arrHTML: any = [];
  //variables funcionalidad drag an drop
  @ViewChild('listSignature',{static: true}) listSignatures;
  @ViewChild('ULSignatures',{static: true}) ULSignatures;
  dragged: any;
  arrConfImages: any;
  widthImageSignatureDefault = 233;
  heightImageSignatureDefault = 100;
  scaleSignature = 0.5;
  rotation = 0;
  allowRotate = true;
  renderingQueue = false;
  counterRender: number = 0;
  constructor(  private modalService: NgbModal,
    private renderer2: Renderer2,private documentslist:  DocumentsService,private DragDrop:DragDrop) {}
  ngOnInit() {
    this.pdfjsLib = window['pdfjs-dist/build/pdf'];
    /*this.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';*/

    this.scale = 1;
    this.numPage = 1;
    this.arrSignatures = new Array();
    if(JSON.parse(localStorage.getItem("listSignatures")) == null){
    this.arrSignatures = [];
    }else{
      this.arrSignatures = JSON.parse(localStorage.getItem("listSignatures"));
      this.signature = this.arrSignatures[0];
    }
    this.arrConfImages = new Array();
  }

  /*--------------------------ADAN */
  closeResult = "";
  sign(content){
    this.modalService
    .open(content, { centered: true, ariaLabelledBy: "modal-basic-title" })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  /**------------------------------------------- */
  //Funcionalidad PDFViewer
  openSecondaryToolbar(){
    this.renderer2.removeClass(this.listSignatures.nativeElement,'open');
     if (this.secondaryToolbar.nativeElement.classList.contains('hidden')) {
       this.renderer2.removeClass(this.secondaryToolbar.nativeElement,"hidden");
    } else {
      this.renderer2.addClass(this.secondaryToolbar.nativeElement,"hidden");
    }
  }
  openList(){
    this.renderer2.addClass(this.secondaryToolbar.nativeElement,"hidden");
    if (this.listSignatures.nativeElement.classList.contains('open')) {
      this.renderer2.removeClass(this.listSignatures.nativeElement,'open');
    } else {
      this.renderer2.addClass(this.listSignatures.nativeElement,'open');
    }
  }
  closeTooltips(){
    this.renderer2.removeClass(this.listSignatures.nativeElement,'open');
    this.renderer2.addClass(this.secondaryToolbar.nativeElement,"hidden");
  }
  // Funcionalidad Firma
  ngAfterViewInit(){
    this.pdfjsLib = window['pdfjs-dist/build/pdf'];
    //console.log("this.url " + this.url);
    if (this.url!=null || this.url != "" ){
      //this.loadPDF(this.url);
      this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    }

  }
  ngOnChanges(changes: SimpleChanges){
    this.pdfjsLib = window['pdfjs-dist/build/pdf'];
    //console.log("Cambio-->",changes);
    if(changes.url != null ){
      //console.log("Cargar nuevo pdf");
      this.loadPDF(changes.url.currentValue);
    }

  }
  clearSignature(){
   this.signaturePad.clear();
  }
  saveSignature() {
    if(this.signaturePad.isEmpty()){
     alert('Favor agregar una firma');
    } else {
      let signature =  this.signaturePad.toDataURL();
      this.hideCaptureSignature = true;
      this.clearSignature();
      this.arrSignatures.push(signature);
      if(this.arrSignatures.length == 1){
        this.signature = this.arrSignatures[0];
      }
      //guardar en localstorage
      let strSignature = JSON.stringify(this.arrSignatures);
      localStorage.setItem("listSignatures",strSignature);
    }
  }
  displaySignature() {
    this.hideCaptureSignature = !this.hideCaptureSignature;
  }
  allowSignaturedisabled = false;
  allowSignaturesave = true;
  insertSignature() {
    if(this.signature == null){
      this.allowSignaturedisabled = false;
      this.allowSignaturesave = true;
      return;
    }
    this.allowSignaturesave = false;
    this.allowSignaturedisabled = true;
    let divPage = this.arrHTML[this.numPage - 1].divPage;
    let divCanvasWrapper = this.arrHTML[this.numPage - 1].divCanvasWrapper;
    let divImg = this.renderer2.createElement("div");
    //Agregar signature al final de la hoja
    let widthDivPage = this.arrHTML[this.numPage - 1].canvas.width;
    let hDivPage = this.arrHTML[this.numPage - 1].canvas.height;
    //let posY = hDivPage - (this.heightImageSignatureDefault * this.scale);
    //Fin Agregar signature al final de la hoja
    this.renderer2.addClass(divImg,"moveSignature");
    this.renderer2.setStyle(divImg,"width",(this.widthImageSignatureDefault * this.scale)+"px");
    this.renderer2.setStyle(divImg,"height",(this.heightImageSignatureDefault * this.scale)+"px");
    let img = this.renderer2.createElement("img");
    this.renderer2.setAttribute(img,"src",this.signature);

    let divDeleteSignature = this.renderer2.createElement("div");
    this.renderer2.addClass(divDeleteSignature,"deleteSignature");
    this.renderer2.appendChild(divImg,divDeleteSignature);
    this.renderer2.appendChild(divImg,img);
    //Funcionalidad Drag and Drop
    let dragImage:DragRef = this.DragDrop.createDrag(divImg);
    dragImage.withBoundaryElement(divCanvasWrapper);
    dragImage.ended.subscribe((eventData)=>{
      this.dragEnd(eventData as unknown as CdkDragEnd);
    });
    dragImage.started.subscribe((eventData)=>{
      this.dragStart(eventData as unknown as CdkDragStart);
    });
    this.renderer2.appendChild(divCanvasWrapper,divImg);
    let hP: number = (hDivPage/this.scale);
    let wP: number = (widthDivPage/this.scale);
    let wI = this.widthImageSignatureDefault;
    let hI = this.heightImageSignatureDefault;
  /*  console.log("Width Page-->",wP);
    console.log("Height Page-->",hP);
    console.log("Width Image-->",wI);
    console.log("Height Image-->",hI);*/
    let oX: number;
    let oY: number;
    switch(this.rotation){
      case 0:
        hP = (hDivPage/this.scale);
        wP = (widthDivPage/this.scale);
        //Se obtiene la posicion original, sin escala
        oX = 0;
        oY = (hP - hI);
        break;
      case 90:
        wP = (hDivPage/this.scale);
        hP = (widthDivPage/this.scale);
        //Se obtiene la posicion original, sin escala
        oX = 0;
        oY = 0;
      break;
      case 180:
        hP = (hDivPage/this.scale);
        wP = (widthDivPage/this.scale);
        //Se obtiene la posicion original, sin escala
        oX = (wP - wI);
        oY = 0;
      break;
      case 270:
        wP = (hDivPage/this.scale);
        hP = (widthDivPage/this.scale);
        //Se obtiene la posicion original, sin escala
        //como esta girado, el width se convierte en height y viceversa con respecto ala página
        oY = (wP - hI);
        oX = (hP - wI);
      break;
    }
    // console.log("ox-->",oX);
    // console.log("oy-->",oY);
    this.renderer2.setStyle(divImg,"top",(oY * this.scale)+"px");
    this.renderer2.setStyle(divImg,"left",(oX * this.scale)+"px");
    dragImage.data = {originalPosition:{X: oX, Y: oY}, position: {X:(oX * this.scale),Y: (oY * this.scale)}};
    this.arrConfImages.push(
      {
        divCanvasWrapper: divCanvasWrapper,
        divImg: divImg,
        dragImage: dragImage,
        image: img,
        page: this.numPage,
        imageBase64: this.signature,
        positionX: oX,
        positionY: oY,
        widthPage:wP,
        heightPage:hP,
        orientationPositionX:oX,
        orientationPositionY:oY
    });
    let i = (this.arrConfImages.length - 1);
    this.updatePosition(this.arrConfImages[i],oX,oY);
    // console.log("Array images-->",this.arrConfImages);

    this.renderer2.listen(divDeleteSignature,"click",(event)=>{
      this.deleteSignature(divCanvasWrapper,divImg);
    });
    //Scroll a firma
    const y = divPage.getBoundingClientRect().top;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
    this.viewContainer.nativeElement.scrollTop = (divPage.offsetTop + (oY/2));
    //Fin scroll
  }

  selectSignature(e,i) {
    if (this.ULSignatures.nativeElement.hasChildNodes()) {
      var children = this.ULSignatures.nativeElement.childNodes;
      //console.log("children-->",children);
      for (let j = 0; j < children.length; j++) {
        if (children[j].classList != undefined) {
          if (children[j].classList.contains('select')) {
              this.renderer2.removeClass(children[j],"select");
          }
        }
      }
    }
    let parentLi = this.renderer2.parentNode(e.target);
    this.renderer2.addClass(parentLi,"select");
    this.signature = this.arrSignatures[i];
  }
  changeColor(r,g,b){
    const color = 'rgb('+r+','+g+','+b+')';
    this.signaturePad.penColor = color;
  }
  dragStart(e: CdkDragStart){
    let dragElement =  e.source.getRootElement();
    dragElement.style.opacity = '0.8';
    dragElement.style.cursor = 'move';
    dragElement.style.backgroundColor  = '#B4B4B4';
  }
  dragEnd(e: CdkDragEnd){
    // console.log("Drag End-->",e);
    //quitando efecto
    let dragElement =  e.source.getRootElement();
    dragElement.style.opacity = '';
    dragElement.style.cursor = '';
    dragElement.style.backgroundColor  = 'transparent';
    //obteniendo posicion de elemento, por evento drag
    let posElement = e.source.getFreeDragPosition();
    let opX: number = e.source.data.originalPosition.X;
    let opY: number = e.source.data.originalPosition.Y;
    // console.log("Drag End oX-->",opX);
    // console.log("Drag End oY-->",opY);
    // console.log("PosElement-->",posElement);
    //Calcular nueva posicion, en escala
    e.source.data.position.X = (opX * this.scale) + posElement.x;
    e.source.data.position.Y = (opY * this.scale) + posElement.y;
    // console.log("DragEnd position-->",e.source.data.position);
    const index = this.arrConfImages.findIndex(item => item.divImg === dragElement);
    //se actualiza, posicion sin escala, en array de imagenes
    this.updatePosition(this.arrConfImages[index],(e.source.data.position.X / this.scale),(e.source.data.position.Y/this.scale));
  }


  deleteSignature(page,divImg){
    this.renderer2.removeChild(page, divImg);
    const index = this.arrConfImages.findIndex(item => item.divImg === this.dragged);
    this.arrConfImages.splice(index,1);
  }
  zoomIn(){
    let selectZoom = <HTMLSelectElement>document.getElementById("scaleSelect");
    if((selectZoom.selectedIndex + 1) < selectZoom.options.length){
      selectZoom.selectedIndex += 1;
      this.scale = selectZoom.options[selectZoom.selectedIndex].value;
      this.changeZoom();
    }
  }

  zoomOut(){
    let selectZoom = <HTMLSelectElement>document.getElementById("scaleSelect");
    if((selectZoom.selectedIndex - 1) >= 0 ){
      selectZoom.selectedIndex -= 1;
      this.scale = selectZoom.options[selectZoom.selectedIndex].value;
      this.changeZoom();
    }
  }
  changeZoom(){
    this.renderPage(this.pdfDocument,this.numPage);
    if(this.numPage>1) this.renderPage(this.pdfDocument,this.numPage - 1);
    if(this.numPage<this.totalPage) this.renderPage(this.pdfDocument,this.numPage + 1);
    this.updateSignatureScale();
  }

  //PDF metodos
  async loadPDF(urlPDF){
   // console.log("Loading pdf---------------------");
    const pvc = this;

    pvc.renderer2.addClass(pvc.loadingBar.nativeElement,'show');
    pvc.renderer2.removeClass(pvc.loadingBar.nativeElement,'hidden');
    pvc.renderer2.setStyle(pvc.loadingBarProgress.nativeElement,"width","1%");
    pvc.allowRotate = false;
    pvc.destroy();
    //console.log("pvc.pdfjsLib ------>>>>>> " + pvc.pdfjsLib);
    console.log("url pdf-->",urlPDF);
    let loadingTask = pvc.pdfjsLib.getDocument(urlPDF);
    //console.log(loadingTask);
    /*PDF con contraseña
    loadingTask.onPassword = (updateCallback, reason) => {
      this.pdfLinkService.externalLinkEnabled = false;
      this.passwordPrompt.setUpdateCallback(updateCallback, reason);
      this.passwordPrompt.open();
    };*/
    loadingTask.onProgress = ({ loaded, total }) => {
      pvc.renderer2.setStyle(pvc.loadingBarProgress.nativeElement,"width",((loaded/total)*100)+"%");
    };

    loadingTask.promise.then(
      pdf => {
        pvc.pdfDocument = pdf;

        //console.log(pvc.pdfDocument);

        pvc.totalPage = pdf.numPages;
        document.getElementById("numPages").innerText = pdf.numPages;

        for(let numPage = 1; numPage <= pdf.numPages; numPage ++) {
          //Creando contenido html, para las paginas
          //const rotation_identifier = pvc.pdfDocument._transport.pageCache;//.pageCache[0]._pageInfo.rotate;1
          const rotation_identifier =  Object(pdf._transport.pageCache);
         // console.log(  "-------------------------------------");
            //console.log(rotation_identifier);
         // console.log(  "-------------------------------------");

          let divPage = pvc.renderer2.createElement('div');

            pvc.renderer2.addClass(divPage,'page');
            pvc.renderer2.setAttribute(divPage,'data-page-number',''+numPage);
            pvc.renderer2.setAttribute(divPage,'role','region');
            pvc.renderer2.setAttribute(divPage,'aria-label','Page '+numPage);
            pvc.renderer2.setAttribute(divPage,'data-loaded','false');
            let divLoading=pvc.renderer2.createElement('div');
            pvc.renderer2.addClass(divLoading,'loadingIcon');
            pvc.renderer2.setAttribute(divLoading,'role','img');
            pvc.renderer2.setAttribute(divLoading,'aria-label','Loading…');
            let divCanvasWrapper = pvc.renderer2.createElement('div');
            pvc.renderer2.addClass(divCanvasWrapper,'canvasWrapper');
            let canvas=pvc.renderer2.createElement('canvas');
            pvc.renderer2.appendChild(divPage,divLoading);
            pvc.renderer2.appendChild(divPage,divCanvasWrapper);
            pvc.renderer2.appendChild(divCanvasWrapper,canvas);
            pvc.renderer2.appendChild(pvc.htmlPdfViewer.nativeElement,divPage);
            let PdfPage = new PdfPageModel(divPage, divLoading,divCanvasWrapper,canvas);

            pvc.arrHTML.push(
                PdfPage
              );
            if(numPage == 1 || numPage == 2){
              pvc.renderPage(pdf, numPage);
              //this.rotate();
            }
          }
      }, exception => {
          return undefined; // Ignore errors for previously opened PDF files.
      }
    );
  }

  //nextPage
  nextPage(){
    if(this.numPage < this.totalPage){
      this.numPage = this.numPage + 1;
      if(this.numPage > 1 ) this.renderPage(this.pdfDocument,this.numPage - 1);
      this.renderPage(this.pdfDocument,this.numPage);
      if(this.numPage<this.totalPage) this.renderPage(this.pdfDocument,this.numPage + 1);
      this.viewContainer.nativeElement.scrollTop = this.arrHTML[this.numPage - 1].divPage.offsetTop;
    } else {
      //mostrar mensaje de error
      return;
    }
  }
  previousPage(){
    if(this.numPage > 1){
      this.numPage = this.numPage - 1;
      if(this.numPage > 1 ) this.renderPage(this.pdfDocument,this.numPage - 1);
      this.renderPage(this.pdfDocument,this.numPage);
      if(this.numPage<this.totalPage) this.renderPage(this.pdfDocument,this.numPage + 1);
      this.viewContainer.nativeElement.scrollTop = this.arrHTML[this.numPage - 1].divPage.offsetTop;
    } else {
      //mostrar mensaje de error
      return;
    }
  }
  scrolling(e){
    //console.log("Scroll-->",e);
    let scrollTop = this.viewContainer.nativeElement.scrollTop
    let distanciaMenor = 999999999;
    for(let div of  e.srcElement.children[0].children){
      let distancia = Math.abs((div.offsetTop - scrollTop));
      if(distanciaMenor > distancia){
        distanciaMenor = distancia;
        this.numPage = parseInt(div.dataset.pageNumber);
        if(this.numPage > 1 ) this.renderPage(this.pdfDocument,this.numPage - 1);
        this.renderPage(this.pdfDocument,this.numPage);
        if(this.numPage<this.totalPage) this.renderPage(this.pdfDocument,this.numPage + 1);
      }
    }
  }
  onKeyEnter(event){
    const numInput = parseInt(event.target.value);
    if(numInput > 0 && numInput <= this.totalPage){
      this.numPage = numInput;
      this.arrHTML[this.numPage - 1].divCanvasWrapper.scrollIntoView();
    }else{
      event.target.value = this.numPage;
    }
  }
  //renderer page
  async renderPage(pdf, numPage) {

    const i = numPage -1;
    let elementHTML: PdfPageModel = this.arrHTML[i];

    const canvas = elementHTML.canvas;
    const divPage = elementHTML.divPage;

    const divCanvasWrapper = elementHTML.divCanvasWrapper;
    let divLoading = elementHTML.divLoading;

    if( !elementHTML.renderingPage){
      if(!elementHTML.dataLoaded || elementHTML.rotation != this.rotation  || elementHTML.scale != this.scale) {
        elementHTML.renderingPage = true;
        this.counterRender += 1;
        this.allowRotate = false;
        // console.log("counterRender-->"+numPage,this.counterRender);
        this.pdfPage(pdf,numPage,elementHTML,divPage,divCanvasWrapper,canvas,divLoading);

      }else{
        return;
      }
    }else{
      //encolar render
      //while(elementHTML.renderingPage);
      //this.pdfPage(pdf,numPage,elementHTML,divPage,divCanvasWrapper,canvas,divLoading);
      return;
    }

  }
  async pdfPage(pdf,numPage,elementHTML,divPage,divCanvasWrapper,canvas,divLoading){
      let pvc = this;
      pdf.getPage(numPage).then(function(page){
        pvc.arrHTML[numPage -1].page =  page;
        var viewport = page.getViewport({
          scale: pvc.scale});
          /*var defaultViewport = pdfPage.getViewport(1);
          var neededViewport = defaultViewport.clone({scale: needScale, rotation: needRotation, dontFlip: true});
  */

          //console.log("--------------------viewport");
          //console.log(viewport.rotation);
          if (viewport.rotation > 0){////Este corrige documentos que están rotados.
            pvc.rotation = viewport.rotation;
            viewport = viewport.clone({scale: pvc.scale, rotation: pvc.rotation});
          }

          if (pvc.rotation > 0){/////Este es para rotarlo manualmente.
            viewport = viewport.clone({scale: pvc.scale, rotation:pvc.rotation});
          }

        // console.log("--------------------viewport despues");
        //  console.log(viewport.rotation);

          pvc.renderer2.setAttribute(canvas,'height',viewport.height);
          pvc.renderer2.setAttribute(canvas,'width',viewport.width);
          pvc.renderer2.setStyle(divCanvasWrapper,'width',viewport.width+'px');
          pvc.renderer2.setStyle(divCanvasWrapper,'height',viewport.height+'px');
          pvc.renderer2.setStyle(divPage,'width',(viewport.width)+'px');
          pvc.renderer2.setStyle(divPage,'height',(viewport.height)+'px');
          pvc.renderer2.setStyle(divLoading,'width',(viewport.width)+'px');
          pvc.renderer2.setStyle(divLoading,'height',(viewport.height)+'px');

        var context = canvas.getContext("2d");
        //context.drawImage(pdf, 0, 0);
        // Render PDF page into canvas context
        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };

        var renderTask = page.render(renderContext);

        // Wait for rendering to finish
        renderTask.promise.then(function() {
          pvc.counterRender -= 1;
          // console.log("counterRender fin render -->"+numPage,pvc.counterRender);
          if(pvc.counterRender < 1){
            pvc.allowRotate = true;
            pvc.counterRender = 0;
          }
          elementHTML.renderingPage = false;
          elementHTML.dataLoaded = true;
          elementHTML.scale = pvc.scale;
          elementHTML.rotation = pvc.rotation;

          pvc.renderer2.setAttribute(divPage,'data-loaded','true')
          pvc.renderer2.setStyle(elementHTML.divLoading,'display','none');
          pvc.renderer2.addClass(pvc.loadingBar.nativeElement,'hidden');

        });

    });
  }
  async addImageToPDF(){
    this.renderer2.addClass(this.savingSignature.nativeElement,"show");
    const existingPdfBytes = await fetch(this.url).then(res => res.arrayBuffer());
    const pdfDocument = await PDFDocument.load(existingPdfBytes);
    // console.log("ADD_IMAGE-->",this.arrConfImages);
    for(let objImage of this.arrConfImages){
      // console.log("Guardar position x-->",objImage.positionX);
      // console.log("Guardar position y-->",objImage.positionY);
      let arrayPng = Uint8Array.from(atob(objImage.imageBase64.replace('data:image/png;base64,','')), c => c.charCodeAt(0));
      let pngImage = await pdfDocument.embedPng(arrayPng);
      let pngDims = pngImage.scale(this.scaleSignature);

      let pages = pdfDocument.getPages();


      pages[(objImage.page - 1)].drawImage(pngImage, {
        x: objImage.positionX,
        y: (pages[(objImage.page - 1)].getHeight() - pngDims.height - objImage.positionY),
        width: pngDims.width,
        height: pngDims.height,
        rotate: degrees(this.rotation)
      });
    }

    const pdfBytes  = await pdfDocument.save();
    let base64String =btoa(new Uint8Array(pdfBytes).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
      }, ''));
      return base64String;
  }
  async savePDF() {
      let base64String = await this.addImageToPDF()
      const fileU   = {} as uploadFileModel;
      fileU.file  = base64String;
      fileU.document_id         = this.fileU.document_id;
      fileU.compamny_id         = localStorage.getItem('customerBranchId');
      fileU.company_branch_id   = localStorage.getItem('customerBranch');
      fileU.customer_id         = this.fileU.customer_id;
      fileU.customer_branch_id  = this.fileU.customer_branch_id;
      fileU.file_track_id       = this.fileU.file_track_id;
      fileU.year                = new Date().getFullYear();
      fileU.month               = new Date().getMonth();
      fileU.bucket_name         = this.fileU.bucket_name;
      fileU.original_file_name  = this.fileU.original_file_name;
      fileU.file_name_in_bucket = this.fileU.original_file_name;
      fileU.file_type           = this.fileU.file_type;
      fileU.filename_path       = this.fileU.original_file_name;
      fileU.last_version        = this.fileU.last_version;

      let filesUploadRechazados = new Array();
      filesUploadRechazados.push(fileU);
      let listUploadFile= {data: {}};
      listUploadFile.data = filesUploadRechazados;

      this.documentslist.actualizarArchivo(JSON.stringify(listUploadFile)).subscribe((res: any)=>{
        if (res.code == 200){
          this.url = this.getSignedUrlAWS(fileU.bucket_name, res.message);
          this.allowSignaturesave = true;
          this.allowSignaturedisabled = false;
          this.loadPDF(this.url);
        }
        this.renderer2.removeClass(this.savingSignature.nativeElement,"show");
        this.savePDFEvent.emit(base64String);
      });


     // console.log("Emitiendo evento de actualización.");

  }
  getSignedUrlAWS(bucket, pathFile) : string{
    const aws = require('aws-sdk');
    let s3 = new AWS.S3({
      accessKeyId: 'AKIAZI5VJPNY4HKDNAEK',
      secretAccessKey: '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',
      region:'us-east-2'
    });
    const url = s3.getSignedUrl('getObject', {
      Bucket: bucket,
      Key: pathFile,
      Expires: 8000,
      ResponseContentType: 'application/pdf'
  })
    return url;
  }
  async downloadPDF(){
      let dataURL = await fetch(this.url).then(res => res.blob());

      let filename = this.fileU.original_file_name;

      if(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1){
        window.open(this.url);
      }else{
        let blob = dataURL;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        //document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
  }
  ///printpdf
  async printPDF(){

    let dataURL = await fetch(this.url).then(res => res.blob());
    let objectURL = window.URL.createObjectURL(dataURL);
    let iframe = <HTMLIFrameElement> document.querySelector('#pdf-frame');

    iframe.src = objectURL;
    window.URL.revokeObjectURL(objectURL);

    window.setTimeout(function() {
      iframe.contentWindow.print();
    }, 1000);

  }
  async rotate(rotate){
    this.rotation += rotate;
    this.rotation %= 360;
    if (this.rotation < 0) {
      this.rotation += 360;
    }
    //console.log("rotando a: " + this.rotation );
    this.renderPage(this.pdfDocument,this.numPage);
    if(this.numPage>1) this.renderPage(this.pdfDocument,this.numPage - 1);
    if(this.numPage<this.totalPage) this.renderPage(this.pdfDocument,this.numPage + 1);
    this.updateSignaturesRotate();

  }
  /**
   * Funcíon se ejecuta en cambio de escala
   */
  updateSignatureScale(){
    // console.log("Cambio Scale-->");
    for(let itemSignature of this.arrConfImages){
      let dragImage = itemSignature.dragImage as DragRef;
      //Obtenemos dimensiones de la imagen, original
      let wI = this.widthImageSignatureDefault;
      let hI = this.heightImageSignatureDefault;
      //Obtenemos posicion sin escala, de la orientacion
      // console.log("Posicion sin escala-->",itemSignature);
      let posX = itemSignature.orientationPositionX;
      let posY = itemSignature.orientationPositionY;
      //Obtenemos Posicion original sin escala
      // console.log("Posicion original sin escala-->",dragImage.data.originalPosition);
      let oX = (posX * this.scale);
      let oY =  (posY * this.scale);

      //asignando nueva posicion con escala
      // console.log("Cambio scale position itemSignature-->",itemSignature);
      dragImage.data.position.X =  oX;
      dragImage.data.position.Y = oY;
      dragImage.data.originalPosition.X = posX;
      dragImage.data.originalPosition.Y = posY;
      // console.log("Cambio scale position-->",dragImage.data);

      let elementImage = dragImage.getRootElement();
      elementImage.style.top = oY+"px";
      elementImage.style.left = oX+"px";
      elementImage.style.width = (wI * this.scale)+"px";
      elementImage.style.height = (hI * this.scale)+"px";
      dragImage.getRootElement().style.transform = "translate3d(0px, 0px, 0px)";
      //Se vuelve a construir el drag, por que se cambiaron la posiciones
      let data = dragImage.data;
      dragImage.dispose();
      itemSignature.dragImage = this.DragDrop.createDrag(itemSignature.divImg);
      itemSignature.dragImage.data = data
      itemSignature.dragImage.withBoundaryElement(itemSignature.divCanvasWrapper);
      itemSignature.dragImage.ended.subscribe((eventData)=>{
        this.dragEnd(eventData as unknown as CdkDragEnd);
     });
      itemSignature.dragImage.started.subscribe((eventData)=>{
       this.dragStart(eventData as unknown as CdkDragStart);
      });

      //elementImage.style.transform = "translate3d("+tX+"px, "+tY+"px, 0px)";
    }

  }
  /**
   * La función se ejecuta, en cambio de zoom y scale, para cambiar el posicionamiento
   * de las firmas(vista)
   */
  updateSignaturesRotate(){
    // console.log("Cambio rotate-->");
    for(let itemSignature of this.arrConfImages){
      /*Cambio de escala*/
      let dragImage = itemSignature.dragImage as DragRef;
     //Obtenemos width y height, original 0°, scale 1
      let wP = itemSignature.widthPage;
      let hP = itemSignature.heightPage;
      //Obtenemos dimensiones de la imagen, original
      let wI = this.widthImageSignatureDefault;
      let hI = this.heightImageSignatureDefault;
      //Obtenemos posicion, original 0°, scale 1
      //let posX = itemSignature.positionX;
      //let posY = itemSignature.positionY;
      //Declaramos variables, para calculo de posicion
      let oX: number;
      let oY: number;
      //let tX: number;
      //let tY: number;


      switch(this.rotation){
        case 0:
          //Se obtiene la posicion original, sin escala
          oX = 0;
          oY = (hP - hI);
          break;
        case 90:
          //Se obtiene la posicion original, sin escala
          oX = 0;
          oY = 0;
        break;
        case 180:
          //Se obtiene la posicion original, sin escala
          oX = (wP - wI);
          oY = 0;
        break;
        case 270:
          //Se obtiene la posicion original, sin escala
          //como esta girado, el width se convierte en height y viceversa con respecto ala página
          oX = (hP - wI);
          oY = (wP - hI);
        break;
      }
      dragImage.getRootElement().style.top = (oY *  this.scale)+"px";
      dragImage.getRootElement().style.left = (oX *  this.scale)+"px";
      dragImage.getRootElement().style.transform = "translate3d(0px, 0px, 0px)";
      // console.log("ox:",oX);
      // console.log("ox:",oY);
      //Se actualiza la nueva posicion
      this.updatePosition(itemSignature, oX, oY);
      //Calculamos posicion equivalente a scale y rotacion
      dragImage.data.position.X = oX * this.scale;
      dragImage.data.position.Y = oY * this.scale;
      dragImage.data.originalPosition.X = oX;
      dragImage.data.originalPosition.Y = oY;
      //Se vuelve a construir el drag, por que se cambiaron la posiciones
      let data = dragImage.data;
      dragImage.dispose();
      itemSignature.dragImage = this.DragDrop.createDrag(itemSignature.divImg);
      itemSignature.dragImage.data = data
      itemSignature.dragImage.withBoundaryElement(itemSignature.divCanvasWrapper);
      itemSignature.dragImage.ended.subscribe((eventData)=>{
        this.dragEnd(eventData as unknown as CdkDragEnd);
     });
      itemSignature.dragImage.started.subscribe((eventData)=>{
       this.dragStart(eventData as unknown as CdkDragStart);
      });
    }

  }
  /**
   * Se obtiene posicion real, en 0° y scale 1
   * se ejecuta en el evento dragEnd, por que cambia la posicion*/
  updatePosition(itemSignature, x, y){
    //Se declaran variables
    let Wp: number = itemSignature.widthPage;
    let Hp: number = itemSignature.heightPage;
    // Se obtienen dimensiones de la imagen
    let Wi: number = this.widthImageSignatureDefault;
    let Hi: number = this.heightImageSignatureDefault;
    itemSignature.orientationPositionX = x;
    itemSignature.orientationPositionY = y;
    switch(this.rotation){
      case 0:
        //La posicion del drag, es la misma por que no hay rotacion
        itemSignature.positionX = x;
        itemSignature.positionY = y;
        break;
      case 90:
        // Se calcula la posicion, en 0° y scale 1, sin rotacion,
        //la rotacion se hace al momento de guardar, el pdf
        itemSignature.positionX = (y + Hi) ;
        itemSignature.positionY = (Hp - x - Hi);
        break;
      case 180:
        itemSignature.positionX = (Wp - x);
        itemSignature.positionY = (Hp - y - (2 * Hi));
        break;
      case 270:
        itemSignature.positionX = (Wp - y - Hi);
        itemSignature.positionY = (x - Hi);
        break;
    }
    // console.log("update postion con angulo-->",this.rotation);
    // console.log("update postion variables-->");
    // console.log("wp=",Wp);
    // console.log("hp=",Hp);
    // console.log("wi=",Wi);
    // console.log("hi=",Hi);
    // console.log("UPDATE_ROTATION-->",itemSignature);
    return {position:{x:itemSignature.positionX,y:itemSignature.positionY},anteriorPosition:{x:x,y:y}};
  }
  destroy(){
    //borrar elementos de html paginas
    for(let i = 0;i < this.arrHTML.length; i++){
      this.renderer2.removeChild(this.htmlPdfViewer.nativeElement,this.arrHTML[i].divPage);

    }
    //reiniciar variables
    this.arrHTML = [];
    this.numPage = 1;
    this.arrConfImages = [];
    this.scale = 1;
    this.rotation = 0;
  }

}
