import { Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() layout;
  @Input() menuData;
  viewBreadcrump = true;
  pageInfo = {
    title:  '',
    data: {
      urls: [{title: '', url: ''},
             {title: '', url: ''}
            ]
    }
  };
  menuDefault = {
    title:'Consultas',
    url:'',
    submenu: {
      title:'Reportes',
      url:'/Reportes',
    }
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    //obtener menu
    let menuTemp = JSON.parse(sessionStorage.getItem('menu'));
    for(let itemMenu of menuTemp.data ){
      for(let itemSub of itemMenu.submenu){
        if(this.router.url === itemSub.path){
          // console.log("Se encontro ruta de submenu ------------------------->");
          this.pageInfo.data.urls[0].title = itemMenu.title;
          this.pageInfo.data.urls[0].url = itemMenu.path;
          this.pageInfo.data.urls[1].title = itemSub.title;
          this.pageInfo.data.urls[1].url = itemSub.path;
          this.pageInfo.title = itemSub.title;
        }
      } 
    }
    
    /*this.router.events
      .pipe(filter(event => event instanceof NavigationEnd && !event.url.includes('login')))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        //Buscar menu
        let encontroSubMenu= false;
        //console.log("bread-->",event);
        if(event.menu != null){
          for(let menu of event.menu) {
            if( menu.value == localStorage.getItem('menu')){
              this.setMenu(menu);
              for(let submenu of menu.submenu){
                if( submenu.value == localStorage.getItem('submenu')){
                  this.setSubMenu(submenu);
                  encontroSubMenu = true;
                  break;
                }
              }
              break;
            }
          }
        }
        if(!encontroSubMenu) {
          //Opcion default del componente, buscar menú por url
          this.router.url
        }
      });*/

     
  }
  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges ) {
    if(changes.menuData.currentValue != null ) {
       //Menu padre
    this.setMenu(changes.menuData.currentValue.menu);
    //Menu Hijo
    this.setSubMenu(changes.menuData.currentValue.submenu);
    }
    if(this.router.url == '/publishDocument'){
      this.viewBreadcrump = false;
    }else{
      this.viewBreadcrump = true;
    }
  }
  setMenu(menu: any){
    this.pageInfo.data.urls[0].title = menu.title;
    this.pageInfo.data.urls[0].url = (menu.url == null) ? menu.path : menu.url;

  }
  setSubMenu(submenu: any){
    this.pageInfo.data.urls[1].title = submenu.title;
    this.pageInfo.data.urls[1].url = (submenu.url == null) ? submenu.path : submenu.url;
    this.pageInfo.title = submenu.title;
  }
}
